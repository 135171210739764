import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { getHttpParams } from '@shared/utils/http-params';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { BaseHttpService } from './base.service';

@Injectable({
    providedIn: 'root'
})
export class SuppliersService extends BaseHttpService {

    // staging
    private apiURL = `${environment.baseAPIUrl}/web/userentity/suppliers/v1`;

    // local
    // private apiURL = `https://localhost:62341/v1`;

    GetBalance(): Observable<any> {
        return this.get(`${this.apiURL}/balance`);
    }

    CreateSalesGroup(model: CreateSalesGroupModel): Observable<any> {
        return this.post(`${this.apiURL}/sales-groups`, model);
    }

    UpdateSalesGroupName(model): Observable<any> {
        console.log(model);
        return this.put(`${this.apiURL}/sales-groups`, model);
    }

    GetSalesGroups(searchParams): Observable<any> {
        let params = new HttpParams();
        if (searchParams) {
            const keys = Object.keys(searchParams);
            keys.forEach(key => params = params.append(key, searchParams[key]));
        }
        return this.get(`${this.apiURL}/sales-groups`, params);
    }

    getSalesmen(searchParams): Observable<any> {
        const params = getHttpParams(searchParams);
        return this.get(`${this.apiURL}/salespersons`, params);
    }

    CreateSalesPerson(model): Observable<any> {
        return this.post(`${this.apiURL}/SalesPerson`, model);
    }

    CreateBulkSalesPerson(model): Observable<any> {
        return this.post(`${this.apiURL}/BulkCreateSalesPerson`, model);
    }

    updateSalesPerson(model: UpdateSalesPersonModel): Observable<any> {
        return this.put(`${this.apiURL}/sales-person`, model);
    }

    deleteSalesPerson(entityId: number): Observable<any> {
        return this.delete(`${this.apiURL}/sales-person/${entityId}`);
    }

    AssignSalesPersonToGroup(model): Observable<any> {
        return this.post(`${this.apiURL}/assign-sales-person`, model);
    }

    AssignSupervisorToGroup(model): Observable<any> {
        return this.post(`${this.apiURL}/assign-supervisor`, model);
    }

    UnassignSupervisorToGroup(model): Observable<any> {
        return this.post(`${this.apiURL}/unassign-supervisor`, model);
    }

    CreateSupervisor(model): Observable<any> {
        return this.post(`${this.apiURL}/supervisors`, model);
    }

    GetSupervisors(searchParams): Observable<any> {
        let params = new HttpParams();
        if (searchParams) {
            const keys = Object.keys(searchParams);
            keys.forEach(key => params = params.append(key, searchParams[key]));
        }
        return this.get(`${this.apiURL}/supervisors`, params);
    }

    GetPermissions(): Observable<any> {
        return this.get(`${this.apiURL}/permission-management`);
    }

    GetSupervisorsPermissions(): Observable<any> {
        return this.get(`${this.apiURL}/supervisors/permissions`);
    }

    UpdatePermissions(a: any) {
        return this.put(`${this.apiURL}/update-supervisor-permissions`, a);
    }

    GetSupervisor(id: string): Observable<any> {
        return this.get(`${this.apiURL}/supervisors/${id}`);
    }

    UpdateSalesPersonStatus(entityId, suspend: boolean) {
        return this.post(`${this.apiURL}/UpdateSalesPersonStatus`, { suspend, entityId });
    }

    GetSupplierConfiguration(): Observable<any> {
        return this.get(`${this.apiURL}/configuration`);
    }

    SetSuppliersConfiguration(configuration): Observable<any> {
        return this.post(`${this.apiURL}/configuration`, { configuration });
    }

    updateSupervisor(model: UpdateSupervisorModel): Observable<any> {
        return this.put(`${this.apiURL}/supervisors`, model);
    }

    deleteSupervisor(supervisorId: string): Observable<any> {
        return this.delete(`${this.apiURL}/supervisors/${supervisorId}`);
    }

    GetRegions(): Observable<any> {
        return this.get(`${this.apiURL}/regions`);
    }
}

type CreateSalesGroupModel = {
    GroupName: string
}

export type UpdateSupervisorModel = {
    supervisorId: string;
    nameAr?: string;
    nameEn?: string;
    salesGroupIds?: string[];
    IsSuspend?: boolean;
}

export type UpdateSalesPersonModel = {
    entityId: number;
    externalId: string;
    salesGroupId: string;
}
